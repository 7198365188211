import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import comingsoon from "./../images/comingsoon.png";

const SecretPage = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>未公開物件</span>
                </div>
                <h2 class="section-title">Secret</h2>
              </div>
            </div>
          </div>
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        名護市屋我オーシャンフロントA土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市屋我</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>8000坪超（約2,420坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから2 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        沖縄県うるま市宮城島土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県うるま市宮城島</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>3300㎡超（約1000坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから3 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        沖縄県名護市山入端プライベートビーチ付き土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市山入端</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>調査中</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから4 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        名護市屋我オーシャンフロントB土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市屋我</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>56,000㎡超（約16940坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから5 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        沖縄県本部町野原土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県本部町野原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>22100㎡超（約6,700坪)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから6 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        今帰仁村今泊土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県今帰仁村今泊</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>18,500㎡超（約5,590坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから7 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        名護市真喜屋土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市真喜屋</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>550㎡超（約166坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから8 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        大宜味村田港土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県大宜味村</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>23,000㎡超（約7,000坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから9 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        国際通りホテル用土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ホテル</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県那覇市牧志</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>740㎡超（約225坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから10 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        沖縄県宜野座村土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県宜野座村</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>8500㎡超（約2,570坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから11 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        名護市宇茂佐
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市字宇茂佐</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>530㎡超（約160坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        名護市屋我オーシャンフロントA土地
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={comingsoon}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">収益</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ・別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="price-text">
                          <span className="en-family">
                            <strong className="en-number">相談</strong>
                          </span>
                        </div>
                        <div />
                      </div>

                      {/* <div className="comment-box">
                        <p className="">
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                          お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                        </p>
                      </div> */}

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市屋我</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>8000坪超（約2,420坪）</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* ここまで */}
        </div>
      </section>
    </>
  );
};

export default SecretPage;
