import React from "react";

import "./../css/Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-md-7">
                <h4 className="">ベルツコーポレーション株式会社</h4>
                <ul className="list-unstyled">
                  <li>【横浜本社】</li>
                  <li>
                    神奈川県横浜市中区野毛町一丁目23-3クリオ横浜桜木町ラ・モード1F
                  </li>
                  <li>【美ら海営業所】</li>
                  <li>沖縄県名護市屋部862-16レインボーヒル1F</li>
                </ul>
              </div>

              <div class="col-md-4">
                <div class="footer-menu widget-contact-info">
                  <h5>Contact info</h5>
                  <ul className="list-unstyled">
                    <li>【TEL】0120-04-1131</li>
                    <li>
                      【MAIL】
                      <a href="mailto:info@baelzcorporation.jp">
                        info@baelzcorporation.jp
                      </a>
                    </li>
                    <li>【営業時間】10:00～19:00</li>
                    <li>【定休日】水曜日</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="for-sp">
          <div id="sp-fixed-menu">
            <ul className="list-unstyled">
              <li>
                <a href="tel:0120-04-1131">お問い合わせはこちら</a>
              </li>
              {/* <li>
                  <a href="#">資料請求</a>
                  </div>
                </li> */}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
