import React from "react";

import business1 from "./../images/business1.png";
import Information from "./Information";

import "./../css/BusinessPage.css";

const BusinessPage2 = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div className="container container-add">
          <div className="image-container2">
            <img src={business1} alt="Placeholder Image" />
            <div className="overlay"></div>
            <div className="centered-text">
              <p>会社概要</p>
            </div>
          </div>
          <section className="section-business">
            <p className="smaller-title">ベルツコーポレーション</p>
            <h1>弊社の強味</h1>
            <span>
              不動産の買取、仲介、企画設計、からコンサルティングまで
              全てのお客様を笑顔にする事業活動を行っております。
            </span>

            <div className="grid-container">
              <div className="grid-item">
                <p className="smaller-title ">メリット1</p>
                <h5 id="title">多数の仲介実績</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット2</p>
                <h5 id="title">多様な広告出稿</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット3</p>
                <h5 id="title">経験豊富なスタッフ</h5>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">不動産売買仲介業務</h3>
              <div>
                <p className="comment-text">
                  戸建、土地などあらゆる不動産売買、賃貸の仲介業務行っています。是非、理想の住まいや不安だと思うところをお聞かせ下さい。
                </p>
                <p className="comment-text">
                  豊富な経験とノウハウ、そして幅広いネットワークを駆使して、不動産のあらゆる分野で確かな実績と信頼を得ています。
                </p>
              </div>
              <div className="grid-item2">
                <p className="smaller-title ">法人のお客様</p>
                <p className="comment-text">
                  事業用地や、中古住宅、オフィスビル、マンションなど物件の売買から収益不動産の活用まで、ニーズにお応えします。
                </p>
                <p className="comment-text">
                  弁護士、会計士、不動産鑑定士などのネットワークと連携しながら、あらゆる課題に対し的確な提案を行います。
                </p>
              </div>
              <div className="grid-item2">
                <p className="smaller-title ">個人のお客様</p>
                <p className="comment-text">
                  「買いたい」、「売りたい」をお手伝いします。経験豊富なスタッフが、ご相談から物件探し、条件交渉、契約締結業務、引き渡しに至るまでお手伝いさせていただきます。
                </p>
                <p className="comment-text">
                  物件情報と弁護士、会計士などのネットワーク活かした的確なコンサルティングで、個人のお客様の不動産ニーズに、お応えします。
                </p>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">不動産の買取・分譲販売業務</h3>
              <div>
                <p className="comment-text">
                  建売用地・中古住宅・事業用地・マンション用地・収益物件等なんでも買取致します。
                </p>
                <p className="comment-text">
                  ご事情により早期処分をご希望の方や、ご所有の不動産がなかなか売れない方、ご相談ください。
                </p>
                <p className="comment-text">
                  当社が買主となりますので、確実かつスピーディに売却が実現します。
                </p>
                <p className="comment-text">
                  また、当社で買取が出来ない場合、当社ネットワークにより当社取引先不動産業者様にご紹介もいたします。
                </p>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">
                不動産に関するコンサルティング業務
              </h3>
              <div>
                <p className="comment-text">
                  土地有効活用、維持管理、査定、相続、贈与等に関する不動産コンサルティングを行なっています。
                </p>
                <p className="comment-text">
                  また事業土地における開発に必要な法令に関する開発許可設計業務や、事業計画での事業土地・宅地開発許可における企画・計画・コンサルタント業務も取り扱います。
                </p>
              </div>
              <div className="grid-item2">
                <p className="smaller-title ">不動産コンサルティングの業務</p>
                <p className="comment-text">
                  資産評価 土地有効利用 相続対策と相続処理 借地、借家の権利関係
                  定期借地権
                </p>
                <p className="comment-text">
                  都市再開発 不動産競売 不動産信託 不良債権、担保不動産処理
                </p>
                <p className="comment-text">
                  賃貸アパート、マンションなどの企画運営
                </p>
              </div>
            </div>
            <hr />
          </section>
          <section className="section-business">
            <p className="smaller-title">ベルツコーポレーション</p>
            <h1>会社概要</h1>
            <Information />
          </section>
        </div>
      </section>
    </>
  );
};

export default BusinessPage2;
