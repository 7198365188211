import React from "react";

import "./../css/Information.css";

const Information = () => {
  return (
    <>
      <div className="table-box">
        <table id="table01">
          <tr>
            <th>会社名</th>
            <td>ベルツコーポレーション株式会社</td>
          </tr>
          <tr>
            <th>代表取締役</th>
            <td>鈴木 衛</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              【本社】
              <br />
              神奈川県横浜市中区野毛町一丁目23-3 クリオ横浜桜木町ラ・モード1F
              <br />
              TEL 045-334-7658（代表）
              <br />
              FAX 045-334-7678
              <br />
              E-mail info@baelzcorporation.jp
              <br />
              【横浜営業所】
              <br />
              神奈川県横浜市中区福富町西通1-8 IM桜木町ビル4-A
              <br />
              【美ら海営業所】
              <br />
              沖縄県名護市屋部862-16 レインボーヒル 1F
            </td>
          </tr>
          <tr>
            <th>創立</th>
            <td>2016年（平成28年）12月1日</td>
          </tr>
          <tr>
            <th>事業年度</th>
            <td>毎年12月1日から翌年11月30日まで</td>
          </tr>
          <tr>
            <th>主な事業内容</th>
            <td>不動産コンサルティング、売買、仲介、解体工事</td>
          </tr>
          <tr>
            <th>従業員数</th>
            <td>12名（宅地建物取引士5名）</td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>三百万円</td>
          </tr>
          <tr>
            <th>主要取引先</th>
            <td>三井住友銀行、静岡銀行、湘南信用金庫、神奈川銀行</td>
          </tr>
          <tr>
            <th>免許番号</th>
            <td>
              宅地建物取引業者 国土交通大臣免許（1）第10368号
              <br />
              解体工事業者 神奈川県知事（登-1）第2081号
            </td>
          </tr>
          <tr>
            <th>免許番号</th>
            <td>
              宅地建物取引業者 国土交通大臣免許（1）第10368号
              <br />
              解体工事業者 神奈川県知事（登-1）第2081号
            </td>
          </tr>
          <tr>
            <th>加盟団体</th>
            <td>
              （公社）全日本不動産協会{" "}
              <a href="https://www.zennichi.or.jp/">
                https://www.zennichi.or.jp/
              </a>
              <br />
              （公社）不動産保証協会{" "}
              <a href="https://www.fudousanhosho.or.jp/">
                https://www.fudousanhosho.or.jp/
              </a>
            </td>
          </tr>
          <tr>
            <th>関連グループ会社</th>
            <td>
              【ベルリゾート株式会社】
              <br />
              沖縄県名護市屋部862-16 レインボーヒル1F
            </td>
          </tr>
        </table>
      </div>
      <div className="table-box ">
        <table id="table01" className="table-box-color">
          <tr>
            <th>不動産業務実績</th>
            <td>
              横須賀市湘南鷹取5丁目 全41区画 コンサルティング
              <br />
              ※国土交通省、神奈川県及び横須賀市と協議 一団地認定解除（全国初）
              <br />
              鎌倉市笠間3丁目 全6区画 仲介
              <br />
              鎌倉市台4丁目 全3区画 仲介
              <br />
              三浦郡葉山町一色 中古戸建 仲介
              <br />
              鎌倉市腰越3丁目 新築戸建全4棟中3棟 仲介
              <br />
              藤沢市片瀬2丁目 新築戸建仲介（専任媒介）
              <br />
              藤沢市宮前I期〜III期31棟 新築戸建仲介
              <br />
              沖縄県小浜島 土地仲介（専任媒介）
              <br />
              横浜市神奈川区菅田町 土地仲介（専任媒介）
              <br />
              藤沢市片瀬2丁目 新築戸建仲介
              <br />
              神奈川県横須賀市湘南鷹取 全41区画 仲介（専任媒介）
              <br />
              神奈川県藤沢市宮前 仲介
              <br />
              横浜市中区 O邸
            </td>
          </tr>
          <tr>
            <th>解体工事実績</th>
            <td>
              東京都品川区豊町4丁目 RC造社屋
              <br />
              神奈川県横浜市保土ヶ谷区東久保町 木造平屋戸建（手壊し）
              <br />
              神奈川県鎌倉市材木座 RC架台車庫撤去
              <br />
              神奈川県鎌倉市岩瀬1棟木造2階アパート
              <br />
              神奈川県横浜市あざみ野2丁目 木造2階戸建
              <br />
              平塚市代官町 木造2階戸建 RC造2階建
              <br />
              横浜市保土ヶ谷区坂本町 木造2階建てアパート2棟
              <br />
              逗子市小坪5丁目 木造2階建て
              <br />
              藤沢市片瀬2丁目 木造2階建て
              <br />
              横浜市戸塚区名瀬 木造2階建て4棟 アパート6棟
              <br />
              横浜市緑区長津田みなみ台 木造平屋
              <br />
              大和市中央林間4丁目 木造2階建て 残置物
              <br />
              横浜市旭区市沢町 木造2階建て
              <br />
              横浜市保土ヶ谷区岩井町 残置物処分
              <br />
              横浜市港南区上永谷3丁目 木造2階建て 残土処分
              <br />
              横浜市青葉区荏田北2丁目 木造２階建て 残土処分
            </td>
          </tr>
          <tr>
            <th>継続中の業務</th>
            <td>
              農業開発プロジェクト
              <br />
              沖縄県小浜島開発業務
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default Information;
