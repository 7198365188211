import React from "react";
// import { Link } from "react-router-dom";

import "./../css/Header.css";

import logo from "./../images/logo-white.svg";

const Header = () => {
  return (
    <>
      {/* <header className="c-header c-hamburger-menu">
        <div className="container">
          <a href="">
            <img src={logo} width={168} height={30} alt="logo" />
          </a>
          <nav>
          <input
            type="checkbox"
            name="hamburger"
            id="hamburger"
            className="c-hamburger-menu_input"
          />
          <label for="hamburger" className="c-hamburger-menu_bg"></label>

          <ul className="c-header_list c-hamburger-menu_list">
            <li className="c-header_list-item">
              <Link className="c-header_list-link" to="/">
                Home
              </Link>
            </li>
            <li className="c-header_list-item">
              <Link className="c-header_list-link" to="/page1">
                1
              </Link>
            </li>
            <li className="c-header_list-item">
              <Link className="c-header_list-link" to="/page2">
                2
              </Link>
            </li>
          </ul>
          <label for="hamburger" className="c-hamburger-menu_button">
            <span className="c-hamburger-menu_button-mark"></span>
            <span className="c-hamburger-menu_button-mark"></span>
            <span className="c-hamburger-menu_button-mark"></span>
          </label>
          </nav>
        </div>
      </header> */}

      {/* memo */}
      <header className="haikei">
        <div className="container c-header c-hamburger-menu">
          <a href="#" className="c-header__logo">
            <img src={logo} width={168} height={30} alt="logo" />
          </a>
          <input
            type="checkbox"
            name="hamburger"
            id="hamburger"
            className="c-hamburger-menu__input"
          />
          <label for="hamburger" className="c-hamburger-menu__bg"></label>

          <ul className="c-header__list c-hamburger-menu__list">
            <li className="c-header__list-item">
              <a href="/" className="c-header__list-link">
                TOP
              </a>
              {/* <Link to="">TOP</Link> */}
            </li>
            <li className="c-header__list-item">
              <a href="/property" className="c-header__list-link">
                販売中物件
              </a>
            </li>
            <li className="c-header__list-item">
              <a href="/secretpage" className="c-header__list-link">
                未公開物件
              </a>
            </li>
            <li className="c-header__list-item">
              <a href="/businesspage" className="c-header__list-link">
                取扱業務
              </a>
            </li>

            <li className="c-header__list-item">
              <a href="/businesspage2" className="c-header__list-link">
                会社概要
              </a>
            </li>
          </ul>
          <label for="hamburger" className="c-hamburger-menu__button">
            <span className="c-hamburger-menu__button-mark"></span>
            <span className="c-hamburger-menu__button-mark"></span>
            <span className="c-hamburger-menu__button-mark"></span>
          </label>
        </div>
      </header>
    </>
  );
};

export default Header;
