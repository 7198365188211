import React from "react";
import Hero from "../components/Hero";
import Onsale from "../components/Onsale";
import Aboutus from "../components/Aboutus";
import Kanagawa from "../components/Kanagawa";

import "./../css/index.css";
import banner from "./../images/banner.jpg";

const Top = () => {
  return (
    <>
      <Hero />
      {/* <div className="kari">
        <div class="text-container">
          <p class="large-text">最上質の不動産とサービスの提供をめざします</p>
          <p class="medium-text">
            ヴィラリゾートは、最上質な不動産・サービスを感謝の心を込めて提供し
            <br />
            「人生を豊かにする」クリエイティブで新しいライフスタイルを創ります
          </p>
          <p class="small-text">
            沖縄で新しいライフスタイルを実現する価値あるラグジュアリー物件を中心に最上質な不動産をお客様に提供いたします。
          </p>
        </div>
      </div> */}
      <Kanagawa />
      <Onsale />
      <div className="container">
        <div className="image-container">
          <a href="/secretpage">
            <img className="image-contents" src={banner} alt="可変画像" />
          </a>
        </div>
      </div>
      <Aboutus />
    </>
  );
};

export default Top;
