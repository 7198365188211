import React from "react";

import business2 from "./../images/business2.png";

import "./../css/BusinessPage.css";

const BusinessPage = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div className="container container-add">
          <div className="image-container2">
            <img src={business2} alt="Placeholder Image" />
            <div className="overlay"></div>
            <div className="centered-text">
              <p>取扱業務</p>
            </div>
          </div>
          {/* 不動産買取 */}
          <section className="section-business">
            <p className="smaller-title">ベルツコーポレーション</p>
            <h1>不動産買取</h1>

            <div className="grid-container">
              <div className="grid-item">
                <p className="smaller-title ">メリット1</p>
                <h5 id="title">最短3日で現金化</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット2</p>
                <h5 id="title">高額査定</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット3</p>
                <h5 id="title">仲介手数料不要</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット4</p>
                <h5 id="title">秘密厳守</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット5</p>
                <h5 id="title">居住用・事業用OK</h5>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">
                ベルツコーポレーションの不動産買取は
                <br />
                とにかくスピーディ
              </h3>
              <div>
                <p className="comment-text">
                  お客様の諸事情で早急に現金化されたい場合は是非当社にお任せください。
                </p>
                <p className="comment-text">
                  対応エリアは横浜市を中心に神奈川全域、東京23区他首都圏を広域にカバーいたします。
                </p>
                <p className="comment-text">
                  不動産買取は当社が買主になりますので仲介手数料や広告費用など一切不要！
                </p>
                <p className="comment-text">
                  物件の状況によって異なりますが、一度現地を拝見し最短その日のうちに買取金額を提示、3日以内に現金化が可能です。
                </p>
                <p className="comment-text">
                  また不動産売却と違い一般の買主様による内見の手間もありません。売却期間中の室内見学に負担を感じるというお客様にもおすすめです。
                </p>
                <p className="comment-text">
                  ベルツコーポレーションでは居住用以外の事業用物件も買取いたしますので、まずはお気軽にご相談ください！
                </p>
              </div>
            </div>
            <hr />
          </section>
          {/* 不動産売却 */}
          <section className="section-business">
            <p className="smaller-title">ベルツコーポレーション</p>
            <h1>不動産売却</h1>

            <div className="grid-container">
              <div className="grid-item">
                <p className="smaller-title ">メリット1</p>
                <h5 id="title">多数の仲介実績</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット2</p>
                <h5 id="title">多様な広告出稿</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット3</p>
                <h5 id="title">経験豊富なスタッフ</h5>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">
                ベルツコーポレーションは経験豊富なスタッフが多く
                <br />
                様々なケースの不動産売却に対応いたします
              </h3>
              <div>
                <p className="comment-text">
                  不動産売却は購入者を探す期間が必要な為、不動産買取よりもお時間を要しますが、ご希望価格での売却が叶う可能性がございます。
                </p>
                <p className="comment-text">
                  今すぐに売却しなければならない、早急に現金化したいなどの時間的制約がなければ不動産売却を
                </p>
                <p className="comment-text">
                  おすすめしますが、不動産買取と違い仲介手数料や広告費用などの経費もかかり、
                </p>
                <p className="comment-text">
                  また、購入者が見つからず価格の見直しが必要になる場合もございます。
                </p>
                <p className="comment-text">
                  ベルツコーポレーションはお客様のご事情に寄り添った理想的な不動産売却を目指します。
                </p>
                <p className="comment-text">
                  ポータルサイトへの広告出稿や自社ホームページへの掲載、チラシの配布等、売却活動の各フェーズで最適な販売活動をいたします。
                </p>
              </div>
            </div>
            <hr />
          </section>
          {/* 不動産売却 */}
          <section className="section-business">
            <p className="smaller-title">ベルツコーポレーション</p>
            <h1>建物解体工事</h1>

            <div className="grid-container">
              <div className="grid-item">
                <p className="smaller-title ">メリット1</p>
                <h5 id="title">神奈川・東京全域</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット2</p>
                <h5 id="title">経験豊富なスタッフ</h5>
              </div>
              <div className="grid-item">
                <p className="smaller-title ">メリット3</p>
                <h5 id="title">木造から工場まで</h5>
              </div>
            </div>
            <div className="comment-item">
              <h3 className="comment-title">
                ベルツコーポレーションの建物解体業は
                <br />
                一般住宅からビル・工場まで幅広く対応
              </h3>
              <div>
                <p className="comment-text">
                  横浜を中心に神奈川・東京エリア全域をカバーいたします。
                </p>
                <p className="comment-text">
                  経験豊富なスタッフが解体前のスケジュール提案、近隣の方へのご挨拶、解体中の騒音や埃の軽減からアフターフォローまでお客様にご満足いただけるようスタッフに徹底しております。
                </p>
                <p className="comment-text">
                  不動産と建物解体は密接に繋がっております。
                </p>
                <p className="comment-text">
                  どちらも経験豊富なベルツコーポレーションにどうぞご相談ください。
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default BusinessPage;
