import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/navigation";
import "swiper/css";
import "./../css/Hamamoto.css";

import yamakawags1 from "./../images/yamakwagsyoko1.jpg";
import yamakawags2 from "./../images/yamakwagsyoko2.jpg";
import yamakawags3 from "./../images/yamakwagsyoko3.jpg";
import yamakawags4 from "./../images/yamakwagsyoko4.jpg";
import yamakawags5 from "./../images/yamakwagsyoko5.jpg";

const Yamakawags = () => {
  const [thumbs, setThumbs] = useState(null);
  const images = [
    yamakawags1,
    yamakawags2,
    yamakawags3,
    yamakawags4,
    yamakawags5,
  ];

  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div> */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div>
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        本部町山川　美ら海水族館までのメインストリート沿い　向かって左手に位置　面積5000㎡超
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper> */}

                      {/* Swiper2 */}
                      <Swiper
                        className="swiper-hamamoto"
                        loop={true}
                        modules={[Navigation, Thumbs]}
                        thumbs={{
                          swiper: thumbs && !thumbs.destroyed ? thumbs : null,
                        }}
                        navigation
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} className="img-slide3" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className="swiper-hamamoto Swiper1"
                        slidesPerView={5}
                        onSwiper={setThumbs}
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="comment-box">
                        <p className="">
                          {/* 庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。 */}
                        </p>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="box-text">
                          <p>お問い合わせください。</p>
                        </div>
                        <div />
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡本部町山川</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間40分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>5,325.83㎡(1,617.11坪)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>地目</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>用途地域</p>
                          </div>
                          <div className="box-text">
                            <p>未指定</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>建蔽率</p>
                          </div>
                          <div className="box-text">
                            <p>60%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>容積率</p>
                          </div>
                          <div className="box-text">
                            <p>200%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>接道</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>設備</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>現況</p>
                          </div>
                          <div className="box-text">
                            <p>古屋有(更地渡し予定)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>取引態様</p>
                          </div>
                          <div className="box-text">
                            <p>仲介</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Yamakawags;
